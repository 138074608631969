/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default [
  // TODOS
  {
    path: "/",
    name: "home",
    meta: { title: "Home", access: ["administrador", "professor", "aluno"] },
    component: () => import("@/views/aluno/Home.vue"),
  },
  {
    path: "/conta",
    name: "conta",
    meta: { title: "Conta", access: ["administrador", "professor", "aluno"] },
    component: () => import("@/views/conta/Conta.vue"),
  },
  //

  // ADMIN
  {
    path: "/users",
    name: "users",
    meta: { title: "Usuários", access: ["administrador"] },
    component: () => import("@/views/admin/users/UserList.vue"),
  },
  {
    path: "/alunos",
    name: "alunos",
    meta: { title: "Alunos", access: ["administrador"] },
    component: () => import("@/views/admin/alunos/AlunoList.vue"),
  },
  // {
  //   path: "/unidades",
  //   name: "unidades",
  //   meta: { title: "Unidades", access: ["administrador"] },
  //   component: () => import("@/views/admin/unidades/UnidadeList.vue"),
  // },
  {
    path: "/divisoes",
    name: "divisoes",
    meta: { title: "Divisões", access: ["administrador"] },
    component: () => import("@/views/admin/divisoes/DivisaoList.vue"),
  },
  {
    path: "/planos",
    name: "planos",
    meta: { title: "Modalidade", access: ["administrador"] },
    component: () => import("@/views/admin/planos/PlanoList.vue"),
  },
  {
    path: "/cursos",
    name: "cursos",
    meta: { title: "Curso", access: ["administrador"] },
    component: () => import("@/views/admin/cursos/CursoList.vue"),
  },
  {
    path: "/turmas",
    name: "turmas",
    meta: { title: "Turmas", access: ["administrador"] },
    component: () => import("@/views/admin/turmas/TurmaList.vue"),
  },
  {
    path: "/area-postagem",
    name: "area-postagem",
    meta: { title: "Area Postagem", access: ["administrador"] },
    component: () => import("@/views/admin/postagens/AreaPostagem.vue"),
  },
  {
    path: "/nova-postagem",
    name: "nova-postagem",
    meta: { title: "Nova Postagem", access: ["administrador"] },
    component: () => import("@/views/admin/postagens/PostagemForm.vue"),
  },
  {
    path: "/pre-postagens",
    name: "pre-postagens",
    meta: { title: "Area Postagem", access: ["administrador"] },
    component: () => import("@/views/admin/postagens/PostagemPreList.vue"),
  },
  {
    path: "/postagens/gerais",
    name: "postagens-gerais",
    meta: { title: "Postagens Gerais", access: ["administrador"] },
    component: () => import("@/views/admin/postagens/PostagemList.vue"),
  },
  {
    path: "/postagens/turma/:id",
    name: "postagens-por-turma",
    meta: { title: "Postagens da Turma", access: ["administrador"] },
    component: () => import("@/views/admin/postagens/PostagemList.vue"),
  },
  {
    path: "/postagens/curso/:id",
    name: "postagens-por-curso",
    meta: { title: "Postagens do Curso", access: ["administrador"] },
    component: () => import("@/views/admin/postagens/PostagemList.vue"),
  },
  {
    path: "/plantao-duvidas",
    name: "plantao-duvidas",
    meta: { title: "Plantão ao Vivo", access: ["administrador"] },
    component: () =>
      import("@/views/admin/plantao-duvidas/PlantaoDuvidaList.vue"),
  },
  {
    path: "/regimento-interno",
    name: "regimento-interno",
    meta: {
      title: "Regimento Interno",
      access: ["administrador", "assistente"],
    },
    component: () =>
      import("@/views/admin/regimento-internos/RegimentoInternoList.vue"),
  },
  {
    path: "/explorar-cursos",
    name: "explorar-cursos",
    meta: { title: "Explorar Cursos", access: ["administrador"] },
    component: () => import("@/views/admin/cursos/MeusCursos.vue"),
  },
  {
    path: "/disciplinas",
    name: "disciplinas",
    meta: { title: "Disciplinas", access: ["administrador"] },
    component: () => import("@/views/admin/disciplinas/DisciplinaList.vue"),
  },
  {
    path: "/duvidas",
    name: "duvidas",
    meta: { title: "Dúvidas", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/duvidas/DuvidasList.vue"),
  },
  {
    path: "/area-avaliacao",
    name: "area-avaliacao",
    meta: { title: "Área Avaliação", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/avaliacoes/AreaAvaliacao.vue"),
  },
  {
    path: "/diario-classe",
    name: "diario-classe",
    meta: { title: "Diário de Classe", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/diario-classe/DiarioClasse.vue"),
  },
  {
    path: "/lista-de-frequencia",
    name: "lista-de-frequencia",
    meta: { title: "Diário de Classe", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/diario-classe/ListaFrequencia.vue"),
  },
  {
    path: "/turmas-avaliacoes/:id",
    name: "turmas-avaliacoes",
    meta: {
      title: "Avaliações da Turma",
      access: ["administrador", "assistente"],
    },
    component: () => import("@/views/admin/turmas/TurmaAvaliacoes.vue"),
  },
  {
    path: "/avaliacoes/listagem-tipo-avaliacoes",
    name: "avaliacoes-listagem-tipo-avaliacoes",
    meta: { title: "Tipos Avaliações", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/avaliacoes/TipoAvaliacoesList.vue"),
  },
  {
    path: "/avaliacoes/listagem-cursos/:idTipoAvaliacao",
    name: "listagem-cursos",
    meta: {
      title: "Listagem de Cursos",
      access: ["administrador", "professor"],
    },
    component: () => import("@/views/admin/avaliacoes/CursosList.vue"),
  },
  {
    path: "/avaliacoes/listagem-disciplina/:idTipoAvaliacao/:idCurso",
    name: "avaliacao-listagem-disciplina",
    meta: {
      title: "Listagem de Disciplinas",
      access: ["administrador", "professor"],
    },
    component: () => import("@/views/admin/avaliacoes/DisciplinaList.vue"),
  },
  {
    path: "/avaliacoes/:idDisciplina/:idTipoAvaliacao",
    name: "avaliacoes",
    meta: { title: "Avaliações", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/avaliacoes/AvaliacaoList.vue"),
  },
  {
    path: "/lista-avaliacao-notas",
    name: "lista-avaliacao-notas",
    meta: { title: "Avaliações", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/avaliacoes/ListagemEnviarNota.vue"),
  },
  {
    path: "/enviar-nota/:id",
    name: "enviar-nota",
    meta: { title: "Avaliações", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/avaliacoes/notas/EnviarNota.vue"),
  },
  {
    path: "/nova-avaliacao",
    name: "nova-avaliacao",
    meta: { title: "Nova Avaliação", access: ["administrador", "professor"] },
    component: () => import("@/views/admin/avaliacoes/AvaliacaoForm.vue"),
  },
  {
    path: "/area-redacao",
    name: "area-redacao",
    meta: {
      title: "Área Redação",
      access: ["administrador", "assistente"],
    },
    component: () => import("@/views/admin/redacoes/AreaRedacao.vue"),
  },
  {
    path: "/redacoes",
    name: "redacoes",
    meta: {
      title: "Redações",
      access: ["administrador", "assistente"],
    },
    component: () => import("@/views/admin/redacoes/RedacaoList.vue"),
  },
  {
    path: "/nova-redacao",
    name: "nova-redacao",
    meta: {
      title: "Nova Redação",
      access: ["administrador", "assistente"],
    },
    component: () => import("@/views/admin/redacoes/RedacaoForm.vue"),
  },
  {
    path: "/editar-redacao/:id",
    name: "editar-redacao",
    meta: {
      title: "Editar Redação",
      access: ["administrador", "assistente"],
    },
    component: () => import("@/views/admin/redacoes/RedacaoForm.vue"),
  },
  {
    path: "/parametros",
    name: "parametros",
    meta: {
      title: "Competências",
      access: ["administrador", "assistente"],
    },
    component: () =>
      import("@/views/admin/redacoes/parametros/ParametroList.vue"),
  },
  {
    path: "/redacao-relatorio-list",
    name: "redacao-relatorio-list",
    meta: {
      title: "Relatório da Redação",
      access: ["administrador", "assistente", "professor"],
    },
    component: () =>
      import("@/views/admin/redacoes/RedacaoListRelatorio.vue"),
  },
  {
    path: "/redacao-relatorio/:id",
    name: "redacao-relatorio",
    meta: {
      title: "Relatório da Redação",
      access: ["administrador", "assistente", "professor"],
    },
    component: () =>
      import("@/views/admin/redacoes/RedacaoRelatorio.vue"),
  },
  {
    path: "/corrigir-redacoes",
    name: "corrigir-redacoes",
    meta: {
      title: "Corrigir Redações",
      access: ["administrador", "assistente", "professor"],
    },
    component: () => import("@/views/admin/redacoes/AreaCorrigirRedacao.vue"),
  },
  {
    path: "/corrigir-redacoes/disciplina/:id",
    name: "corrigir-redacoes-disciplinas",
    meta: {
      title: "Corrigir Redações",
      access: ["administrador", "assistente", "professor"],
    },
    component: () =>
      import("@/views/admin/redacoes/CorrigirRedacaoDisciplina.vue"),
  },
  {
    path: "/corrigir-redacoes/turmas/:id",
    name: "corrigir-redacoes-turmas",
    meta: {
      title: "Selecionar Turma",
      access: ["administrador", "assistente", "professor"],
    },
    component: () => import("@/views/admin/redacoes/ListagemTurmas.vue"),
  },
  {
    path: "/corrigir-redacoes/corrigidas/:id_redacao/:id_turma",
    name: "corrigir-redacoes-corrigidas",
    meta: {
      title: "Corrigir Redações",
      access: ["administrador", "assistente", "professor"],
    },
    component: () => import("@/views/admin/redacoes/RedacaoCorrigidaList.vue"),
  },
  {
    path: "/corrigir-avaliacoes",
    name: "corrigir-avaliacoes",
    meta: {
      title: "Corrigir Avaliações",
      access: ["administrador", "assistente", "professor"],
    },
    component: () =>
      import("@/views/admin/avaliacoes/AreaCorrigirAvaliacao.vue"),
  },
  {
    path: "/ocorrencias",
    name: "ocorrencias",
    meta: {
      title: "Ocorrências",
      access: ["administrador", "assistente", "professor"],
    },
    component: () =>
      import("@/views/admin/diario-classe/Ocorrencias.vue"),
  },
  //

  // ALUNO
  {
    path: "/ver-aula/:id/:aulaRouterId?",
    name: "ver-aula",
    meta: { title: "Ver Aula", access: ["administrador", "aluno"] },
    component: () => import("@/views/aluno/disciplinas/VerAula.vue"),
    props: true,
  },
  {
    path: "/meus-cursos",
    name: "meus-cursos",
    meta: { title: "Meus Cursos", access: ["aluno"] },
    component: () => import("@/views/aluno/cursos/MeusCursos.vue"),
  },
  {
    path: "/minhas-duvidas",
    name: "minhas-duvidas",
    meta: { title: "Minhas Dúvidas", access: ["aluno"] },
    component: () => import("@/views/aluno/duvidas/MinhasDuvidas.vue"),
  },
  {
    path: "/minhas-avaliacoes",
    name: "minhas-avaliacoes",
    meta: { title: "Avaliações", access: ["aluno"] },
    component: () => import("@/views/aluno/avaliacoes/MinhasAvaliacoes.vue"),
    props: true,
  },
  {
    path: "/area-desempenho",
    name: "area-desempenho",
    meta: { title: "Área de Desempenho", access: ["aluno"] },
    component: () => import("@/views/aluno/area-desempenho/AreaDesempenho.vue"),
    props: true,
  },
  {
    path: "/notas-aluno/:id",
    name: "notas-aluno",
    meta: { title: "Notas do Aluno", access: ["administrador", "assistente"] },
    component: () => import("@/views/admin/avaliacoes/AvaliacoesAluno.vue"),
    props: true,
  },
  {
    path: "/minhas-avaliacoes/:id",
    name: "ver-avaliacoes",
    meta: { title: "Avaliações", access: ["aluno"] },
    component: () => import("@/views/aluno/avaliacoes/VerAvaliacao.vue"),
  },
  {
    path: "/minhas-redacoes",
    name: "minhas-redacoes",
    meta: { title: "Redações", access: ["aluno"] },
    component: () => import("@/views/aluno/redacoes/MinhasRedacoes.vue"),
  },
  {
    path: "/minhas-postagens",
    name: "minhas-postagens",
    meta: { title: "Postagens", access: ["aluno"] },
    component: () => import("@/views/aluno/postagens/MinhasPostagens.vue"),
  },
  {
    path: "/minhas-redacoes/:id",
    name: "ver-redacoes",
    meta: { title: "Redação", access: ["aluno"] },
    component: () => import("@/views/aluno/redacoes/VerRedacao.vue"),
  },
  {
    path: "/quadro-horarios",
    name: "quadro-horarios",
    meta: { title: "Quadro de Horários", access: ["aluno"] },
    component: () => import("@/views/aluno/quadro-horarios/QuadroHorarios.vue"),
    props: true,
  },
  {
    path: "/quadro-de-horarios-admin",
    name: "quadro-de-horarios-admin",
    meta: { title: "Quadro de Horários", access: ["administrador"] },
    component: () =>
      import("@/views/admin/quadro-de-horarios/QuadroDeHorariosList.vue"),
  },
  //
];
